import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import CardPost from "../UI/Card/card-post"

const LastBlogPosts = ({lang = "en"}) => {
    const query = useStaticQuery(graphql`query {
        posts: allStrapiBlogPosts (sort: { fields: [published_at], order: [DESC] }) {
            edges {
                node {
                    id
                    name
                    url
                    published_at(formatString: "DD/MM/YYYY")
                    photo_2 {
                        name
                        alternativeText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 512
                                    placeholder: NONE
                                    formats: [WEBP]
                                    breakpoints: [200, 420, 512]
                                )
                            }
                        }
                    }
                    blog_categories {
                        name
                        url
                    }
                    locale
                }
            }
        }
    }`)
    const baseUrl = lang === "en" ? "" : `/${lang}`
    const posts = query.posts.edges.filter((post) => lang === post.node.locale).slice(0,3)

    return <div className="main__section main__section--100">
        <div className="container">
            <h2>{lang === "en" ? "Latest news" : "Últimas novedades"}</h2>
            <div className="grid-lg-3-h">
                {posts.map((post, index) => {
                    const animation = {"data-aos":"zoom-in", "data-aos-delay": 200 * index}
                    return <CardPost key={index}
                                     lang={lang}
                                     name={post.node.name}
                                     image={post.node.photo_2}
                                     url={`${baseUrl}/blog/${post.node.url}`}
                                     date={post.node.published_at}
                                     tag={post.node.blog_categories[0].name}
                                     tagUrl={`${baseUrl}/blog/${post.node.blog_categories[0].url}`}
                                     showMore={true}
                                     animation={animation}
                    />
                })}
            </div>
        </div>
    </div>
}

export default LastBlogPosts

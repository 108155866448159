import React from "react"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"
import rehypeRaw from "rehype-raw"
import ReactMarkdown from "react-markdown"

const TecalisAdapts = ({ lang }) => {
    const query = useStaticQuery(graphql`query {
        data: allStrapiComponentTecalisAdapts {
            edges {
                node {
                    title
                    description
                    photo {
                        name
                        alternativeText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 608
                                    height: 500
                                    placeholder: BLURRED
                                    formats: [WEBP]
                                )
                            }
                        }
                    }
                    locale
                }
            }
        }
    }`)
    const data = query.data.edges.filter(item => item.node.locale === lang)[0].node

    return <div className="main__section main__section--50 main__section--50--sm">
        <div className="container">
            <div className="container__50">
                <h2>
                    {data.title}
                </h2>
                <div className="container__text">
                    <ReactMarkdown children={data.description} rehypePlugins={[rehypeRaw]} />
                </div>
            </div>
            <div className="container__50">
                <GatsbyImage alt={data.photo.alternativeText} image={getImage(data.photo.localFile)} />
            </div>
        </div>
    </div>
}

export default TecalisAdapts
